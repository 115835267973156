import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import wpService from '../../Services/blogs_api';
import "./dynamicBlog.css";

function BlogDetail() {
  const { postId } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const blogPost = await wpService.getPostById(postId); // Adjust the function to match your API service
        setPost(blogPost);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [postId]);


  console.log("post...." , post)
  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className= "container">

      <img  className="image"
       src={
                  post[0]["_embedded"]["wp:featuredmedia"]
                    ?  post[0]["_embedded"]["wp:featuredmedia"]?.[0]["source_url"]
                    : "/avatar.png"
                } alt={post[0].title.rendered
} />
      <h1 className="title">{post[0].title.rendered
}</h1>
      <div  className="content"
      dangerouslySetInnerHTML={{ __html: post[0].content.rendered }}></div>
      {/* Render other post details as needed */}
    </div>
  );
}

export default BlogDetail;
