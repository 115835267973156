import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import wpService from '../../Services/blogs_api';
import "./categoriesSidebar.css";

function CategoriesSidebar() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const fetchedCategories = await wpService.getAllCategories();
        setCategories(fetchedCategories);
        setFilteredCategories(fetchedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);
console.log("categories" , categories)
useEffect(() => {
 
}, [selectedCategory])

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScroll > lastScrollTop) {
        // Scrolling down
        document.querySelector('aside').style.top = '-60px'; // Hide the sidebar
      } else {
        // Scrolling up
        document.querySelector('aside').style.top = '70px'; // Show the sidebar
      }
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const filtered = categories.filter(category =>
      category.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCategories(filtered);
  }, [searchTerm, categories]);
// console.log("filteredCategories" , filteredCategories) 
  return (
    <aside className={isDropdownOpen ? 'open' : ''}>
      <div className="dropdown-header">
       
        <button className="dropdown-toggle" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          See Categories
        </button>
      </div>
      {(isDropdownOpen || window.innerWidth > 768) && (
        <>
         <h2 className="categories-title">Categories</h2>
          <input
            type="text"
            placeholder="Search categories..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
          />
          <ul className="categories-list">
            {filteredCategories.map((category, index) => (
              <li key={index}  onClick={() =>{ setSelectedCategory(category); setIsDropdownOpen(!isDropdownOpen)}}>
                <Link to={`/blogs/categories/${category.slug}/${category.id}`}>{category.name}</Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </aside>
  );
}

export default CategoriesSidebar;
