import React from "react";
import { HashLink } from 'react-router-hash-link';
import "../../assets/css/main.css";
import "./about_page.css";
import TeamSection from "../Our_Team/our_team";
import { useRef } from "react";
import ReactDOM from "react-dom/client";

function About_Page() {
  return (
    <>
      <section className="who__section" id="about">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-8 mb-40"
                style={{ marginBottom: 10, marginTop: 20 }}
              >
                <h1 className="bridging_the_educational_gap">
                  Edulinx - Bridging the Educational Gap
                </h1>
                <p className="font-md color-text-paragraph-2">
                  {" "}
                  Edulinx is an educational consultancy firm created to reduce
                  the educational disparity between students in developed and
                  developing countries, both at school and university level. Our
                  mission is to ensure that all students have access to quality
                  education regardless of geographical location, economic
                  background, or other factors. We bring together a team of
                  experts in the field of quality education to develop and
                  deliver innovative solutions that address the unique needs of
                  each student in developing countries.
                </p>
                <p className="font-md color-text-paragraph-2">
                  Additionally, we will facilitate inter-university
                  partnerships, which will enable students, faculty, and
                  researchers to exchange knowledge and skills. We intend to
                  play a pivotal role in building a more equitable world where
                  every child has the opportunity to reach their full potential.
                </p>
                <p className="form-messege" />
              </div>
              <div
                className="col-lg-4 text-center d-none d-lg-block"
                style={{
                  marginTop: 30,
                }}
              >
                <img
                  src={require("../../assets/images/about_us2.jpg")}
                  alt="joxBox"
                  className="img-with-margin img-fluid hover-scale"
                  style={{ transition: "transform 0.3s ease" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12 who__img-div"
              style={{ position: "relative" }}
            >
              <div className="who__img-wrapper">
                <div className="who__section-left">
                  <img
                    src={require("../../assets/images/who__img-1.png")}
                    className="who__img-1"
                    alt=""
                  />
                  <img
                    src={require("../../assets/images/who__img-2.png")}
                    className="who__img-2"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12">
              <div className="who__section-right">
                {/* <p className="who__sub-heading">who we are?</p> */}

                <p className="who__heading">
                  The place where you can{" "}
                  <span style={{ color: "#2B6E85" }}>achieve</span>
                </p>
                <p className="who__text">
                  Edulinx sets clear, measurable, and achievable learning
                  outcomes and provides the necessary resources and support
                  system to achieve those outcomes. The quality of practices is
                  ensured by evidence-based teaching and learning strategies and
                  ongoing professional development. Our operations and effective
                  systems of internal quality assurance for continuous
                  improvement. aims to equip learners with the knowledge,
                  skills, and personal attributes needed for success and
                  positive contributions to society.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* New content */}
      <section className="new-content">
        <div className="container">
          <div className="row">

            {/* Mission Card */}
            <div className="col-lg-4 mb-4">
              <div className="card custom-card">
                <svg
                  className="who__card-img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M1 14H2.44444M14 1V2.44444M25.5556 14H27M4.75556 4.75556L5.76667 5.76667M23.2444 4.75556L22.2333 5.76667M10.6778 21.2222H17.3222M9.66667 19.7778C8.45401 18.8683 7.55826 17.6003 7.10629 16.1534C6.65432 14.7066 6.66905 13.1542 7.1484 11.7161C7.62774 10.2781 8.5474 9.02734 9.77709 8.14103C11.0068 7.25471 12.4842 6.77778 14 6.77778C15.5158 6.77778 16.9932 7.25471 18.2229 8.14103C19.4526 9.02734 20.3723 10.2781 20.8516 11.7161C21.3309 13.1542 21.3457 14.7066 20.8937 16.1534C20.4417 17.6003 19.546 18.8683 18.3333 19.7778C17.7694 20.336 17.3448 21.0192 17.0938 21.772C16.8429 22.5248 16.7727 23.3261 16.8889 24.1111C16.8889 24.8773 16.5845 25.6121 16.0428 26.1539C15.501 26.6956 14.7662 27 14 27C13.2338 27 12.499 26.6956 11.9572 26.1539C11.4155 25.6121 11.1111 24.8773 11.1111 24.1111C11.2273 23.3261 11.1571 22.5248 10.9062 21.772C10.6552 21.0192 10.2306 20.336 9.66667 19.7778Z"
                    stroke="#2B6E85"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className="card-body">
                  <h5 className="card-title">Our Mission</h5>
                  <p className="card-text">
                    Our mission is to prepare future generations for a
                    constantly changing world using technology to improve
                    learning, promoting cultural understanding through exchange
                    programs. We believe education is vital to unlocking human
                    potential and aim to provide innovative learning
                    experiences.
                  </p>
                </div>
              </div>
            </div>

            {/* Vision Card */}
            <div className="col-lg-4 mb-4">
              <div className="card custom-card">
                <svg
                  className="who__card-img"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M1.60748 15.9999H5.92523M26.0748 15.9999H30.3925M16 30.3925V26.0747M16 5.92518V1.60742"
                    stroke="#2B6E85"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M13.1215 16H18.8785M16 18.8785V13.1215"
                    stroke="#2B6E85"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.80374 3.53316C10.9907 2.26791 13.4734 1.60355 16 1.60744C23.949 1.60744 30.3925 8.05097 30.3925 16C30.3925 23.949 23.949 30.3925 16 30.3925C8.05101 30.3925 1.60748 23.949 1.60748 16C1.60748 13.3791 2.30839 10.9194 3.5332 8.8037"
                    stroke="#2B6E85"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
                <div className="card-body">
                  <h5 className="card-title">Our Vision</h5>
                  <p className="card-text">
                    To prepare the future generation by leveraging technology to
                    enhance learning, establishing exchange programs to promote
                    cultural understanding, and supporting lifelong learning,
                    with a focus on preparing for a connected world.
                  </p>
                  <p></p>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>

            {/* Core Values Card */}
            <div className="col-lg-4 mb-4">
              <div className="card custom-card">
                <svg
                  className="who__card-img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M1 14H2.44444M14 1V2.44444M25.5556 14H27M4.75556 4.75556L5.76667 5.76667M23.2444 4.75556L22.2333 5.76667M10.6778 21.2222H17.3222M9.66667 19.7778C8.45401 18.8683 7.55826 17.6003 7.10629 16.1534C6.65432 14.7066 6.66905 13.1542 7.1484 11.7161C7.62774 10.2781 8.5474 9.02734 9.77709 8.14103C11.0068 7.25471 12.4842 6.77778 14 6.77778C15.5158 6.77778 16.9932 7.25471 18.2229 8.14103C19.4526 9.02734 20.3723 10.2781 20.8516 11.7161C21.3309 13.1542 21.3457 14.7066 20.8937 16.1534C20.4417 17.6003 19.546 18.8683 18.3333 19.7778C17.7694 20.336 17.3448 21.0192 17.0938 21.772C16.8429 22.5248 16.7727 23.3261 16.8889 24.1111C16.8889 24.8773 16.5845 25.6121 16.0428 26.1539C15.501 26.6956 14.7662 27 14 27C13.2338 27 12.499 26.6956 11.9572 26.1539C11.4155 25.6121 11.1111 24.8773 11.1111 24.1111C11.2273 23.3261 11.1571 22.5248 10.9062 21.772C10.6552 21.0192 10.2306 20.336 9.66667 19.7778Z"
                    stroke="#2B6E85"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className="card-body">
                  <h5 className="card-title">Our Core Values</h5>
                  <ul className="list-unstyled">
                    <li>Professionalism</li>
                    <li>Transparency</li>
                    <li>Honesty</li>
                    <li>Fairness</li>
                    <li>Consistency</li>
                    <li>Sustainability</li>
                    <li>Reliability</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className=" col-md-12">
              <h1 className="bridging_the_educational_gap">Our Initiatives</h1>
              <ul className="list-group custom-list-group">
                <li className="list-group-item d-flex align-items-center">
                  <span className="icon">
                    <i className="fas fa-graduation-cap"></i>{" "}
                    {/* You can choose an appropriate icon here */}
                  </span>
                  <span className="initiative-text">
                    Bringing successful model of skilled-based
                    education, particularly in coding and robotics, to schools
                    in developing countries. We are committed to assisting
                    schools in developing countries to improve their outcomes,
                    teaching and learning practices, quality support services
                    and leadership, management and governance, to make them
                    compatible with the standards of schools in developed
                    countries, by creating an inclusive and equitable education
                    system. Edulinx works closely with schools to identify areas
                    for improvement and provides guidance to make positive
                    changes for the benefit of students and the education system
                    in their respective countries
                  </span>
                </li>
                <li className="list-group-item d-flex align-items-center">
                  <span className="icon">
                    <i className="fas fa-university"></i>{" "}
                    {/* You can choose an appropriate icon here */}
                  </span>
                  <span className="initiative-text">
                    Striving to establish a strong bond between universities in
                    Finland and South Asia by facilitating student exchange
                    programs, faculty and research exchange. The objective is to
                    create opportunities for students and staff to broaden their
                    horizons, gain exposure to new cultures, and exchange ideas.
                    The collaboration seeks to foster knowledge sharing and
                    promote academic excellence while enriching cultural
                    diversity. Such partnerships are expected to fuel
                    innovation, knowledge creation and spur socio-economic
                    progress in the world
                  </span>
                </li>
                <li className="list-group-item d-flex align-items-center">
                  <span className="icon">
                    <i className="fas fa-briefcase"></i>{" "}
                    {/* You can choose an appropriate icon here */}
                  </span>
                  <span className="initiative-text">
                    Aiming to assist multinationals in optimizing their
                    operations by implementing ERP and software solutions that
                    utilize big data and artificial intelligence. We believe
                    that these advanced technologies can help businesses make
                    data-driven decisions that lead to improve efficiency,
                    productivity, and profitability. Our team is dedicated to
                    delivering customized solutions that fit each client's
                    specific needs and goals. With our expertise in cutting-edge
                    technologies
                  </span>
                  
                </li>
                <div  id="test">
              <h1 className=" margt bridging_the_educational_gap">Testimonials</h1>
                  <h2></h2>
                <li className="list-group-item d-flex align-items-center">
                  <span className="icon">
                    <i className="fas fa-briefcase"></i>{" "}
                    {/* You can choose an appropriate icon here */}
                  </span>
                  <span className="initiative-text" >
                  "I've had the pleasure of knowing Mr. Khan for the past 10 years, and I can confidently say that he is one of the best quality assurance consultants and teacher trainers I've ever had the pleasure of working with. Throughout the years, Mr. Khan has provided his expert guidance and support to countless schools, while working with me.
What truly sets Mr. Khan apart is his ability to identify and rectify issues in the most efficient and effective manner possible. He has an uncanny knack for getting to the root of any problem and devising practical solutions that work. His attention to detail is unparalleled, and his steadfast commitment to quality helps ensure that every project he works on is a success.
But Mr. Khan is far more than just a skilled consultant - he's also a phenomenal teacher trainer. He has a passion for sharing his knowledge and expertise with others, and he always has a way of making even the most complex topics accessible to all. He truly cares about helping teachers grow and improve, and his training sessions are always engaging, informative, and fun.
Over the years, I've had the pleasure of working with Mr. Khan on multiple projects, and I can say without hesitation that our collaborations have been some of the most successful I've ever had. He's professional, easy to work with, and always delivers outstanding results. Most importantly, he's someone you can trust to do right by your school and your students.
In short, I wholeheartedly recommend Mr. Khan to any school or organization in need of a top-notch quality assurance consultant or teacher trainer. He is truly one of the best in the business, and I am grateful for his unwavering commitment to excellence."
<br /> <br />

<p >
Dr. Majeed Sanad
<br />
CEO, Success training Centre, Kingdom of Bahrain
<br />
25 years of experience of working with schools in Gulf</p>



                  </span>
                  
                </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
        {/* <TeamSection>

</TeamSection> */}
      </section>
    </>
  );
}

export default About_Page;
