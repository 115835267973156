import React from 'react'
import './header.css'
import { useNavigate } from 'react-router-dom';
function Front_Page() {
    const navigate = useNavigate();
    const handleButtonClick = () => {
        navigate('/contact-us');
    };
  return (
    <>
    <div className="header">

   
    <div className="container-fluid">
    <div className="row hero__section">
        <div className="col-xl-6 col-lg-6 col-12 hero__content-wrapper">
            <div className="hero__data">
                {/* <p className="hero__sub-heading">Our Pillars of Excellence</p> */}
                <h1 className="hero__heading">Reinventing the future, One Step at a time</h1>
                <p className="hero__text">We collaborate with educational institutions and multinational organizations to future-proof their operations and stay ahead in today's tech-driven landscape</p>
                <button className="hero__btn "
                onClick={handleButtonClick}>Get in Touch</button>
            </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-0"></div>

    </div>
</div>

<div className="header__section__bottom">

    {/* <img src="assets/images/Subtract.png" alt=""> */}
    <img src={require('../../assets/images/Subtract.png')} />
</div>
</div></>
  )}
  
  export default Front_Page;