import { useState, useEffect } from "react";
// import { SearchIcon } from "../icons";


function SearchIcon(props) 
{
    const style = props.style || '';   
    
    return (
      <svg className={style} viewBox="0 0 19 19"  xmlns="http://www.w3.org/2000/svg">
            <title id="title">Search</title>
            <circle cx="7.5" cy="7.5" r="6.5" fill="white" stroke="currentColor" strokeWidth="2"/>
            <line x1="12.7071" y1="12.2929" x2="17.9379" y2="17.5237" stroke="currentColor" strokeWidth="2"/>
      </svg>
    )
}

const BlogsSidebar = ({ category, handleSearchpost, selectedCategory }) => {
  const [searchPost, setSearchPost] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    handleSearchpost(searchPost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPost]);
  return (
    <div>
      {/* ........................Menu Icon................................... */}
      <div className="p-2  px-4 flex lg:hidden justify-between sticky z-20">
        <p className="font-default text-lg text-semibold text-black ">Filter</p>
        {showSidebar ? (
          <button
            className={`flex text-4xl text-primary-blue items-center cursor-pointer  right-0 top-6 z-50 `}
            onClick={() => setShowSidebar(!showSidebar)}
          >
            x
          </button>
        ) : (
          <svg
            onClick={() => setShowSidebar(!showSidebar)}
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
            />
          </svg>
        )}
      </div>

      {/* ........................Search bar................................... */}

      <div
        className={` -z-1  text-white h-screen    ease-in-out duration-300 ${
          showSidebar ? "" : "hidden lg:block "
        }`}
      >
        <div className="flex  pt-5 mx-5">
          <div
            className={` w-full md:w-search-bar border rounded flex flex-col relative`}
          >
            <div
              className={`h-search-bar w-full flex items-center text-gray-400 focus-within:text-gray-600`}
            >
              <SearchIcon style="absolute w-5 h-5 ml-3 pointer-events-none " />
              <input
                name="search"
                type="text"
                className={`text-sm w-full h-full pl-10 rounded border border-[#cccccc]`}
                autoComplete="off"
                placeholder="Search for Blogs"
                value={searchPost}
                onChange={(even) => {
                  setSearchPost(even.target.value);
                }}
              />
            </div>
          </div>
        </div>

        {/* ........................Category List................................... */}

        <div className="flex flex-col mt-5 mx-5 ">
          <h3 className="text-lg  font-default font-bold py-3 border-b-4 border-[D2D2D2]">
            CATEGORIES
          </h3>

          <ul className="lg:text-sm md:text-lg from-neutral-100 max-h-[500px] overflow-y-auto ">
            {category?.map((categorys, index) => {
              return (
                <a href={categorys.link} key={index}>
                  <div
                    className={` ${
                      categorys.slug === selectedCategory
                        ? "text-primary-red"
                        : "text-black"
                    } cursor-pointer border-b-2 hover:text-primary-red`}
                  >
                    <li className="pb-2 pt-5">
                      <ul className=" flex justify-between cursor-pointer">
                        <li>{categorys.name}</li>
                        <li>{`(${categorys.count})`}</li>
                      </ul>
                    </li>
                  </div>
                </a>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BlogsSidebar;
