// src/components/FaqPage.js
import React from 'react';
import FaqList from '../components/FAQ/Faq_list';


const FaqPage = () => {

    const faqs = [
        {
          question: 'What is Quality Assurance for schools?',
          answer: 
            <div>
              <p>
                Quality assurance for schools refers to the process of monitoring and evaluating educational standards and practices
                to ensure that students receive a high-quality education. It involves establishing benchmarks and criteria for
                teaching, learning, and school operations, and regularly assessing performance and progress against those benchmarks.
              </p>
              <p>
                Quality assurance also includes reviewing curriculum, teacher training and support, assessment and evaluation
                procedures, and ensuring that students have access to resources and opportunities that support their academic and
                personal development. Ultimately, the goal of quality assurance in schools is to ensure that students achieve their
                full potential and are well-prepared for future success.
              </p>
            </div>
          ,
        },
        {
          question: 'What is EValuate?',
          answer: 
            <div>
              <p>
                EValuate is a mobile app that measures three domains: Quality of school outcome, quality practices, and the role of leadership,
                management, and governance. These three domains are further divided into five aspects:
              </p>
              <ul>
                <li>
                  <strong>Children's scholastic achievement:</strong> This domain focuses on academic performance and includes factors
                  such as student test scores, graduation rates, and a comparison between exam scores and skill-sets of students who go
                  on to the next grade. It also considers the quality of instructional materials, curriculum design, and academic
                  support services provided to students.
                </li>
                <li>
                  <strong>Personal, social, and emotional development:</strong> This domain explores how well schools are meeting the
                  non-academic needs of students. This includes things like developing social skills, emotional intelligence, and
                  positive self-image. Schools that foster positive relationships among students and between students and teachers
                  are likely to score highly in this domain.
                </li>
                <li>
                  <strong>Teaching and learning:</strong> This domain examines the quality of teaching and the effectiveness of instructional
                  methods. It looks at things like teacher preparation, classroom practice, and the use of data to evaluate student progress.
                  Schools that provide ongoing professional development for teachers and embrace evidence-based teaching practices are
                  likely to score well in this domain.
                </li>
                <li>
                  <strong>Students' empowerment:</strong> This domain assesses the extent to which students are given a voice in their own
                  education. This includes opportunities for students to participate in decision-making processes, such as selecting their
                  own courses or co-designing curriculum. Schools that focus on student empowerment give students more ownership of their
                  education and create a sense of agency and self-efficacy.
                </li>
                <li>
                  <strong>Leadership, management, and governance:</strong> This domain evaluates how well schools are run and how effectively
                  they are managed. This includes factors such as school climate, administrative leadership, and financial management.
                  Schools that have strong, visionary leadership and a culture of transparency and accountability are likely to perform
                  well in this domain.
                </li>
              </ul>
            </div>
          ,
        },
        {
          question: 'What is the process for quality assurance in schools, and how are information and feedback gathered from various stakeholders, including parents and students, to arrive at the final judgment?',
          answer: 
            <div>
              <p>
                Our process starts with asking schools to fill out an online Self Review Form (SRF. This form allows the school to reflect
                on their processes, policies, and practices to determine areas that need improvement. We also request information on the
                school's results in terms of academic performance, student attendance, and achievement of their learning objectives.
              </p>
              <p>
                Next, we engage parents and children to provide feedback on the school's performance. We conduct surveys and hold focus groups
                with parents to get their perspective on the school's strengths and weaknesses. Similarly, we engage children to get their feedback
                on their learning experience, teaching methods, and extracurricular activities offered.
              </p>
              <p>
                Based on the self-evaluation and parent and student feedback, our quality assurance experts form a preliminary judgment. They will
                assess the school's performance across all areas and determine whether there are any areas that require further investigation.
              </p>
              <p>
                Finally, our quality assurance experts visit the school to complete their assessment. They will conduct interviews with teachers,
                observe classes, and review documentation to form a final judgment on the quality of education provided by the school.
              </p>
              <p>
                Overall, our quality assurance process is comprehensive and based on multiple sources of feedback. We strive to provide objective
                and evidence-based assessments to schools to help them improve their educational outcomes.
              </p>
            </div>
          ,
        },
        {
          question: 'What is the difference between a review visit and a monitoring visit?',
          answer: 
            <div>
              <p>
                There are two types of school visits: Review visits occur every five years, while monitoring visits take place annually.
                Schools that score satisfactorily or higher will only undergo review visits every five years. However, schools that fall
                under the inadequate category will receive annual evaluations until they reach a satisfactory ranking.
              </p>
            </div>
          ,
        },
        {
          question: 'What is the next course of action if a school is categorized as inadequate?',
          answer: 
            <div>
              <p>
                If a school is classified as inadequate, it will be required to create a post-review action plan and an impact statement
                detailing the actions taken within one year. Our team will visit for a single day to reassess the school's performance and
                come to a final judgment.
              </p>
            </div>
          ,
        },
      
        {
          question: 'What are our Grading Rubrics or grading scales? ',
          answer:  <div>
          <p>
            Schools are evaluated using a four-point grading scale. Schools that demonstrate exceptional performance across all aspects
            are given an "Outstanding" rating and serve as a benchmark for others. Schools that are commendable for their overall performance
            but don't excel across all aspects are given a "Good"  rating. Schools that meet minimum standards but need improvements in some areas are given a "Satisfactory" rating, while
            schools that fail to meet minimum standards across several aspects are given an "Inadequate" rating and need immediate interventions.
          </p>
        </div> },




{
question: 'What is Future Unified School Network (FUSN) model?',
answer: 
<div>
<p>
Future Unified School Network (FUSN) is a newly founded international chain of schools franchise that has been established
after extensive research and studying of many successful international schooling systems. The core focus of FUSN is to adopt
a futuristic approach when it comes to education and to offer a skilled-based curriculum that prepares students for the challenges
of the future.
</p>
<p>
The curriculum at FUSN is designed to equip students with the necessary skills required to succeed in today's fast-paced and
rapidly changing environment. This includes subjects such as coding, robotics, and life skills, which are essential for the workforce
of the future.
</p>
<p>
FUSN has a dedicated research team that is continuously working to update the curriculum and ensure that it remains relevant and
cutting-edge. The goal of this research team is to stay ahead of the curve and create a curriculum that is always one step ahead
of the latest trends and technologies.
</p>
<p>
In addition to this, FUSN also has a quality assurance wing that works closely with schools to ensure the holistic development of
students. This includes achieving academic excellence, as well as regulating schools in terms of their quality of outcomes, practices,
and leadership, management, and governance.
</p>
<p>
Overall, FUSN is an educational institution that is dedicated to providing students with the tools and skills they need to succeed
in the 21st century. By adopting a futuristic approach and staying ahead of the latest trends and technologies, FUSN is setting a new
standard for education and will continue to do so for many years to come.
</p>
</div>
,
},


{
  question: "What sets the FUSN Islamic Schooling System apart from the existing educational systems in Pakistan?",
  answer: (
    <div>
      <p>
        Fusn Islamic International School is a unique educational concept that merges conventional schooling and Islamic schooling system into a comprehensive and holistic approach to education. The vision of this school is to produce and nurture Muslim scholars who possess the same level of competence and skill as professionals in fields such as computer engineering, business, medicine, and other fields. We believe that it is essential for the next generation of Muslim scholars to have diverse and versatile abilities in order to thrive and excel in today's world.
      </p>
    </div>
  ),
  },
  {
  question: "How much time children spend on Arabic Curriculum, as compared to other subjects from early years?",
  answer: (
    <div>
      <p>
        We allocate 60% of our time towards the subject of Arabic and 40% towards other languages and other subjects starting from Grade-1 onwards. Subsequently, as students progress through each class, the allocated time for Arabic gradually decreases.
      </p>
    </div>
  ),
  },
  {
  question: "What type of graduate we envision to produce?",
  answer: (
    <div>
      <p>
        We aim to instill a profound understanding of moral and ethical principles in our graduates that mold them into virtuous Muslim scholars, while also equipping them with holistic knowledge across various disciplines.
      </p>
    </div>
  ),
  },
  {
  question: "What Curriculum we teach to our students?",
  answer: (
    <div>
      <p>
        Right from the beginning, we instill Quran memorization, Islamic education, and a variety of subjects such as Urdu, Math, Science, English Language, Coding, Robotics, and Life skills to foster the growth of a well-rounded individual.
      </p>
    </div>
  ),
  },
  {
  question: "What are the key competencies and knowledge that our students should attain in Islamic education for a successful life?",
  answer: (
    <div>
      <p>
        We strictly adhere to our policy that children are required to complete the Hifz of Quran after completing grade 5. Additionally, upon completing grade 12, they are fully capable of delivering sermons in both English and Arabic.
      </p>
    </div>
  ),
  },
{
question: 'Who can apply for affiliation?',
answer: 
<div>
<p>
The Future Unified School Network (FUSN) model is a  reputed model imparting quality education to its affiliates.
Our organization offers the opportunity for existing schools or investors willing to start a new school to choose either of these models
for their institution.
</p>
<p>
To apply for affiliation, the school must meet minimum criteria laid down on our website. This criteria includes having adequate infrastructure,
facilities, and resources to provide quality education to students. The school must also have qualified and experienced teachers, and must
adhere to the curriculum and guidelines specified for the chosen model.
</p>
<p>
Once a school is affiliated with us, we provide extensive support and guidance to ensure that the school meets the standards set by us. We
conduct regular training sessions for teachers, monitor student performance, and provide feedback to the school management to help them improve
their offerings.
</p>
<p>
Overall, we are committed to ensuring that every affiliated school provides a holistic learning experience to its students, and fosters their
intellectual, social, and emotional growth. We invite interested parties to apply for affiliation and join us in our mission to provide quality
education to every child.
</p>
</div>
,
},

{
  question: 'What do we offer to our schools?',
  answer:
    <div>
      <p>
        Our goal is to work in partnership with the management of your school so that we can achieve our objective of making your school one of the top-performing institutions
        in the region. We believe that our partnership will greatly benefit your school, its students, and the community at large.
      </p>
      <p>
        In addition to the above, we offer the following to our affiliated schools:
      </p>
      <ul>
        <li>
          <strong>Comprehensive Curriculum:</strong> We provide a comprehensive and up-to-date curriculum designed to enhance student learning and growth.
        </li>
        <li>
          <strong>Promotional Support:</strong> We run promotional campaigns that showcase the unique identity and offerings of your school to attract prospective students and parents.
        </li>
        <li>
          <strong>Teacher Training and Development:</strong> We invest in teacher training and development to ensure that your academic staff is equipped with the necessary tools and skills to deliver quality education.
        </li>
        {/* Add more list items as needed */}
      </ul>
      <p>
        We are committed to ensuring that every affiliated school provides a holistic learning experience to its students and fosters their intellectual, social, and emotional growth.
        Our ultimate aim is to contribute to the development of well-rounded individuals who are prepared for success in the 21st century.
      </p>
    </div>
  ,
},



// Add other FAQ items here


        // Add other FAQ items here
      ];
  return (
<div className="faq-page">
  <h1 style={{ textAlign: 'center',   marginTop: '20px' }}>Frequently Asked Questions</h1>
  <FaqList faqs={faqs} />
</div>



  );
};

export default FaqPage;
