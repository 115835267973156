import React, { useState } from 'react'
import "../../assets/css/main.css";
import './news.css'

function News() {
    const [email, setEmail] = useState('');
    const [subscribed, setSubscribed] = useState(false);
    const [error, setError] = useState(false); 
    // Step 3: Define the subscribe_newsletter function
    const subscribe_newsletter = () => {
      // Implement your subscription logic here
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      setError(true);
      return; // Do not proceed if email is invalid
    }
      console.log('Subscribed to newsletter:', email);
  
      // Step 4: Clear the email input field by updating the state
      setSubscribed(true);
      setError(false);
      setEmail('');
      
      setTimeout(() => {
        setSubscribed(false);
      }, 5000); // Reset after 5 seconds
    };
  return (
    <>
                <section class="news__section" id="contact">

<svg class="news__plane" xmlns="http://www.w3.org/2000/svg" width="59" height="59" viewBox="0 0 59 59"
    fill="none">
    <path
        d="M15.045 36.833L3.49785 25.2858C2.95452 24.743 2.55455 24.0738 2.33387 23.3382C2.11319 22.6026 2.07873 21.8237 2.23356 21.0715C2.38357 20.2445 2.75173 19.4724 3.29998 18.8353C3.84823 18.1981 4.55669 17.7189 5.35213 17.4472L50.5714 2.36009C51.4187 2.04522 52.3386 1.98026 53.2217 2.17292C54.1048 2.36558 54.914 2.80776 55.5532 3.44691C56.1923 4.08606 56.6345 4.89529 56.8272 5.7784C57.0198 6.66152 56.9549 7.58139 56.64 8.42866L41.5528 53.648C41.2991 54.4169 40.8519 55.1077 40.2541 55.6538C39.6563 56.2 38.928 56.5832 38.1393 56.7665C37.3555 56.9593 36.5349 56.944 35.7588 56.7223C34.9827 56.5005 34.278 56.08 33.7143 55.5022L24.5271 46.3572L14.6657 51.4565L15.045 36.833ZM15.045 36.833L55.2914 3.28724"
        stroke="black" stroke-linecap="round" stroke-linejoin="round" />
</svg>

<img src={require('../../assets/images/news-circles.png')} alt="" class="news__circles" />

<div class="news__data">
    <p class="news__heading">Subscribe newsletters</p>
    <p class="news__text">Enter your email address to register to our newsletter subscription delivered
        on a regular basis </p>

    <div class="email__bar">
        <div class="email__icon-wrapper">
            <svg class="email__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 16" fill="none">
                <path d="M6 5L11 8.5L16 5" stroke="#737373" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M1 13V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H19C19.5304 1 20.0391 1.21071 20.4142 1.58579C20.7893 1.96086 21 2.46957 21 3V13C21 13.5304 20.7893 14.0391 20.4142 14.4142C20.0391 14.7893 19.5304 15 19 15H3C2.46957 15 1.96086 14.7893 1.58579 14.4142C1.21071 14.0391 1 13.5304 1 13Z"
                    stroke="#737373" stroke-width="1.5" />
            </svg>
        </div>
        <input type="email" name="email" id="email" class="email__input" placeholder="Enter Your Email"
        value={email} // Bind the input value to the state variable
        onChange={(e) => setEmail(e.target.value)} // Update state on input change
            required />
        <button class="email__button" type="submit" onClick={subscribe_newsletter}>Subscribe</button>
    </div>
    {error && (
          <p className="error-message">Invalid email format. Please enter a valid email address.</p>
        )}
    {subscribed && (
          <p className="success-message">Successfully subscribed to the newsletter!</p>
        )}
</div>


</section>
    </>
  )
}

export default News