import React from 'react'
import "../../assets/css/main.css";
import './services_page.css'

function Services_Page() {
  return (
    <>



 <div class="Margfix">


    
  
        <div class="container">
        <div class="row">

<div class="  shadow-lg p-3 mb-5 bg-white rounded column cl1"><h1 class="bridging_the_educational_gap" >Holistic school development program</h1>
<p class="size Mar ">Our holistic school development program aims to enhance all aspects of a school, including academics, social-emotional development, physical health, and improving role of leadership, management and governance. It involves curriculum development, teacher training, student assessment, parent and community involvement, and fostering a school culture that supports holistic learning. Such a program improves academic performance, physical well-being, social-emotional skills, and fosters a sense of community among students.</p>
</div>


<div class=" shadow-lg p-3 mb-5 bg-white rounded column cl2 "><h1 class="bridging_the_educational_gap">Edulinx International Schooling System</h1><p class="size Mar"> Edulinx sets clear, measurable, and achievable learning outcomes and provides the necessary resources and support system to achieve those outcomes. The quality of practices is ensured by evidence-based teaching and learning strategies and ongoing professional development. Our operations and effective systems of internal quality assurance for continuous improvement. aims to equip learners with the knowledge, skills, and personal attributes needed for success and positive contributions to society</p>
</div>
</div>
    <div class="row align-items-center">

        <div class="col-lg-6 col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 opt-sm-0">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-6">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mt-4 pt-2">
                        <h5 class=" bridging_the_educational_gap">FUSN (Future Unified School Network) Franchise  </h5>
                            <div class="card work-desk rounded border-0 shadow-lg overflow-hidden p-2">
                            <p class="size Margin11l ">
                            Future Unified School Network (FUSN) is a newly founded international chain of schools franchise that has been established after extensive research and studying of many successful international schooling systems. The core focus of FUSN is to adopt a futuristic approach when it comes to education and to offer a skilled-based curriculum that prepares students for the challenges of the future.</p>
                                <div class="img-overlay bg-dark"></div>
                            </div>
                        </div>
                     

                       
                    </div>
                 
                </div>
            

                <div class="col-lg-6 col-md-6 col-6">
                    <div class="row">
                        {/* <div class="col-lg-12 col-md-12">
                        <h5 class=" bridging_the_educational_gap">Transforming schools into Finish Model</h5>
                            <div class="card work-desk rounded border-0 shadow-lg overflow-hidden p-2" >
                            <p  class="size Margin11l ">The Finish schooling model is a unique educational approach that prioritizes student well-being and equal opportunities for all learners. Compared to traditional schooling methods, the Finish model places greater emphasis on creativity, critical thinking, and problem-solving skills. It is based on a comprehensive curriculum including not only academic subjects, but also life skills and social and emotional learning. Finish schools consistently score high in international rankings and have been recognized for their innovative approaches to education.</p>
                                <div class="img-overlay bg-dark"></div>
                            </div>
                        </div> */}
                     

                        <div class="col-lg-12 col-md-12 mt-4 pt-2 ">
                        <h5 class="bridging_the_educational_gap ">Complete school contextualization.</h5>
                            <div class="card work-desk rounded border-0 shadow-lg overflow-hidden p-2 " >
                            <p  class="size Margin11l " >School contextualized refers to an approach that takes into account the unique circumstances and challenges faced by individual schools. It involves tailoring education programs and support services to address the specific needs of a school and its students, based on factors such as the school's location, student population, and available resources. By contextualizing education in this way, schools can better engage and support their students, leading to improved academic outcomes and overall success.</p>
                               
                                <div class="img-overlay bg-dark"></div>
                            </div>
                        </div>
                    
                    </div>
                  
                </div>
              
            </div>
           
        </div>
  

        <div class="col-lg-6 col-md-6 col-12 order-1 order-md-2 ">
            <div class="section-title ml-lg-6 bottm">
                <h3 class="text-custom font-weight-normal mb-3 mt-4 adjuss1">What we do?</h3>
                <h5 class="title mb-4 adjuss">
                “Develop a passion for learning. If you do, you will never cease to grow.” <br /> <br/>— Anthony J. D’Angelo 
                   
                </h5>
                <img class="addedimg img-flui" src="/services 1.jpg" alt="" /> 
                
                <br/><br /><br /><br />
                <img class="addedimg1 img-flui" src="/services 2.jpg" alt="" />
               
                
                        
            </div>
        </div>
       
    </div>

</div>


<div className="container spi">
          <div className="row">
          {/* <div className="col-md-12">
  <h1 className="bridging_the_educational_gap" >Finish curriculum from KG to grade-12</h1>

  <p class="textint">The Finnish education system emphasizes equal opportunities and individualized learning. The curriculum is designed to provide students with a strong foundation in literacy, numeracy, and problem-solving skills. The following is a rough outline of the subjects taught at different levels in the Finnish education system: </p>
  <ul className="list-group custom-list-group">
    <li className="list-group-item d-flex align-items-center">
      <span className="icon">
        <i className="fas fa-graduation-cap"></i> 
      </span>
      <span className="initiative-text">
      <b>Kindergarten:</b> Children start school at the age of seven. Before that, they have the option to attend pre-school. In kindergarten, the focus is on play-based learning and developing social and emotional skills. 
		
      </span>
    </li>
    <li className="list-group-item d-flex align-items-center">
      <span className="icon">
        <i className="fas fa-university"></i> 
      </span>
      <span className="initiative-text">
      <b>Primary School (grades 1-6): </b>The focus is on reading, writing, mathematics, science, history, geography, music, art, physical education, and crafts. Learning is integrated, and there is no streaming or tracking. 
      </span>
    </li>
    <li className="list-group-item d-flex align-items-center">
      <span className="icon">
        <i className="fas fa-briefcase"></i> 
      </span>
      <span className="initiative-text">
      <b>Lower secondary school (grades 7-9): </b>Students continue to study the same subjects as before, along with foreign languages (usually English, Swedish, or German), home economics, health education and some electives. Students are taught by subject-specific teachers, and there is some streaming, but it is not based on performance. 
      </span>
    </li>
    <li className="list-group-item d-flex align-items-center">
      <span className="icon">
        <i className="fas fa-university"></i> 
      </span>
      <span className="initiative-text">
      <b>Upper Secondary School (grades 10-12): </b> This is a three-year program that prepares students for university or vocational education. Students may choose to pursue either the general upper secondary education track or vocational education. In the general upper secondary education track, students study their chosen subjects in depth and are also required to study at least one foreign language. In vocational education, students learn practical skills in fields such as healthcare, IT, or business.
      </span>
    </li>
  </ul>
</div> */}


          </div>
        </div>

      


</div>

</>
  )
}

export default Services_Page