import React from 'react'
import "../../assets/css/main.css";
import './about.css'

function About() {
  return (
    <>
    <section className="who__section" id="about">
    <div className="container-fluid">
        <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 who__img-div" style={{position: 'relative'}}>
                <div className="who__img-wrapper">
                    <div className="who__section-left">
                        <img src={require('../../assets/images/who__img-1.png')} className="who__img-1" alt="" />
                        <img src={require('../../assets/images/who__img-2.png')} className="who__img-2" alt="" />
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12">
                <div className="who__section-right">
                    <p className="who__sub-heading">who we are?</p>
                    <p className="who__heading">The place where you can <span style={{color: '#2B6E85'}}>achieve</span>
                    </p>
                    <p className="who__text">Edulinx sets clear, measurable, and achievable learning outcomes and
                        provides the necessary resources and support system to achieve those outcomes. The
                        quality of practices is ensured by evidence-based teaching and learning strategies and
                        ongoing professional development. Our operations and effective systems of internal
                        quality assurance for continuous improvement. aims to equip learners with the knowledge,
                        skills, and personal attributes needed for success and positive contributions to
                        society.</p>

                   
                </div>
                <div className="who__cards-wrapper">
                        <article className="who__card">
                            <div className="who__card-img-wrapper">
                                <svg className="who__card-img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 28 28" fill="none">
                                    <path
                                        d="M1 14H2.44444M14 1V2.44444M25.5556 14H27M4.75556 4.75556L5.76667 5.76667M23.2444 4.75556L22.2333 5.76667M10.6778 21.2222H17.3222M9.66667 19.7778C8.45401 18.8683 7.55826 17.6003 7.10629 16.1534C6.65432 14.7066 6.66905 13.1542 7.1484 11.7161C7.62774 10.2781 8.5474 9.02734 9.77709 8.14103C11.0068 7.25471 12.4842 6.77778 14 6.77778C15.5158 6.77778 16.9932 7.25471 18.2229 8.14103C19.4526 9.02734 20.3723 10.2781 20.8516 11.7161C21.3309 13.1542 21.3457 14.7066 20.8937 16.1534C20.4417 17.6003 19.546 18.8683 18.3333 19.7778C17.7694 20.336 17.3448 21.0192 17.0938 21.772C16.8429 22.5248 16.7727 23.3261 16.8889 24.1111C16.8889 24.8773 16.5845 25.6121 16.0428 26.1539C15.501 26.6956 14.7662 27 14 27C13.2338 27 12.499 26.6956 11.9572 26.1539C11.4155 25.6121 11.1111 24.8773 11.1111 24.1111C11.2273 23.3261 11.1571 22.5248 10.9062 21.772C10.6552 21.0192 10.2306 20.336 9.66667 19.7778Z"
                                        stroke="#2B6E85" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className="who__card-data">
                                <p className="who__card-heading">our mission</p>
                                <p className="who__card-text">Our mission is to prepare future generations for a constantly changing world using technology to improve learning, promoting cultural understanding through exchange programs. 
                                We believe education is vital to unlocking human potential, and aim to provide innovative learning experiences.
                                </p>
                            </div>
                        </article>

                        <article className="who__card">
                            <div className="who__card-img-wrapper">
                                <svg className="who__card-img" xmlns="http://www.w3.org/2000/svg" width="32"
                                    height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                        d="M1.60748 15.9999H5.92523M26.0748 15.9999H30.3925M16 30.3925V26.0747M16 5.92518V1.60742"
                                        stroke="#2B6E85" stroke-width="1.5" stroke-linecap="round" />
                                    <path d="M13.1215 16H18.8785M16 18.8785V13.1215" stroke="#2B6E85"
                                        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path
                                        d="M8.80374 3.53316C10.9907 2.26791 13.4734 1.60355 16 1.60744C23.949 1.60744 30.3925 8.05097 30.3925 16C30.3925 23.949 23.949 30.3925 16 30.3925C8.05101 30.3925 1.60748 23.949 1.60748 16C1.60748 13.3791 2.30839 10.9194 3.5332 8.8037"
                                        stroke="#2B6E85" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                            </div>
                            <div className="who__card-data">
                                <p className="who__card-heading">our Vision</p>
                                <p className="who__card-text">To prepare future generation  by leveraging technology to enhance learning, establishing exchange programs to promote cultural understanding, and supporting lifelong learning, with a focus on preparing for a connected world.</p>
                            </div>
                        </article>

                        <article className="who__card">
                            <div className="who__card-img-wrapper">
                                <svg className="who__card-img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 28 28" fill="none">
                                    <path
                                        d="M1 14H2.44444M14 1V2.44444M25.5556 14H27M4.75556 4.75556L5.76667 5.76667M23.2444 4.75556L22.2333 5.76667M10.6778 21.2222H17.3222M9.66667 19.7778C8.45401 18.8683 7.55826 17.6003 7.10629 16.1534C6.65432 14.7066 6.66905 13.1542 7.1484 11.7161C7.62774 10.2781 8.5474 9.02734 9.77709 8.14103C11.0068 7.25471 12.4842 6.77778 14 6.77778C15.5158 6.77778 16.9932 7.25471 18.2229 8.14103C19.4526 9.02734 20.3723 10.2781 20.8516 11.7161C21.3309 13.1542 21.3457 14.7066 20.8937 16.1534C20.4417 17.6003 19.546 18.8683 18.3333 19.7778C17.7694 20.336 17.3448 21.0192 17.0938 21.772C16.8429 22.5248 16.7727 23.3261 16.8889 24.1111C16.8889 24.8773 16.5845 25.6121 16.0428 26.1539C15.501 26.6956 14.7662 27 14 27C13.2338 27 12.499 26.6956 11.9572 26.1539C11.4155 25.6121 11.1111 24.8773 11.1111 24.1111C11.2273 23.3261 11.1571 22.5248 10.9062 21.772C10.6552 21.0192 10.2306 20.336 9.66667 19.7778Z"
                                        stroke="#2B6E85" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className="who__card-data">
                                <p className="who__card-heading">Our Core Values</p>
                                <p className="who__card-text">Professionalism, Transparency, Honesty, Fairness,
                                    Consistency, Sustainability and Reliability.</p>
                            </div>
                        </article>

                    </div>
                
            </div>
        </div>
    </div>
</section>


</>
  )
}

export default About