// src/components/FaqList.js
import React from 'react';
import FaqItem from './FAQ';


const FaqList = ({ faqs }) => {
  return (
    <div className="faq-list">
      {faqs.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FaqList;
