import React from 'react'
import "../../assets/css/main.css";
import "./WhyChooseUs.css";
import How_Support_Instituions from '../how_support_institutions/how_support_instituions';

function WhyChooseUs() {
  return (
    <>
            <section class="benefit__section" id="facilities">
         
            <p class="section__title">Why Choose Us</p>

            <p class="section__text">We support institutions to solve following basic key questions.</p>
            <How_Support_Instituions>
                    </How_Support_Instituions>
            <p class="section__title" style = {{margintop : 20}}>Our Pillers of Excellence</p>

            <div class="container-fluid" style={{paddingBottom:"340px"}}>
                <div class="row">

                    <div class="col-xl-6 col-lg-6 col-12 benefit__left-div">
                        <div class="benefit__img-wrapper ">
                            {/* <div class="benefit__section-left"> */}

                                {/* <svg class="benefit__img-top-circle" xmlns="http://www.w3.org/2000/svg" width="635"
                                    height="373" viewBox="0 0 635 373" fill="none">
                                    <path
                                        d="M610.439 3.00004C628.448 206.545 488.185 350 318.192 350C148.199 350 1.79669 204.852 25.5364 3.00004"
                                        stroke="url(#paint0_linear_271_62)" stroke-width="45" />
                                    <defs>
                                        <linearGradient id="paint0_linear_271_62" x1="358" y1="366" x2="354" y2="10.5"
                                            gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#458094" />
                                            <stop offset="0.482131" stop-color="#458094" stop-opacity="0.94" />
                                            <stop offset="1" stop-color="#458094" stop-opacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <svg class="benefit__img-bottom-circle" xmlns="http://www.w3.org/2000/svg" width="635"
                                    height="373" viewBox="0 0 635 373" fill="none">
                                    <path
                                        d="M610.439 3.00004C628.448 206.545 488.185 350 318.192 350C148.199 350 1.79669 204.852 25.5364 3.00004"
                                        stroke="url(#paint0_linear_271_62)" stroke-width="45" />
                                    <defs>
                                        <linearGradient id="paint0_linear_271_62" x1="358" y1="366" x2="354" y2="10.5"
                                            gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#458094" />
                                            <stop offset="0.482131" stop-color="#458094" stop-opacity="0.94" />
                                            <stop offset="1" stop-color="#458094" stop-opacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>

                                <div class="benefit__img__inner">
                                    <img src={require('../../assets/images/girl.png')} alt="" class="benefit__modal img-fluid" />
                                    <div class="benefit__img-inner-circle"></div>
                                </div> */}

                                <img src={require('../../assets/images/girl-with-circles.png')} class="img-fluid w-100 benefit__img" alt="" />


                            {/* </div> */}
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-12 ">
                        <div class="benefit__section-right">
                            <svg class="benefit__number-path" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 475"
                                fill="none">
                                <path
                                    d="M1.87756 474.065C51.4636 405.184 78.0975 322.437 77.9996 237.565C77.9018 152.693 51.0773 70.0068 1.3326 1.24078"
                                    stroke="black" stroke-dasharray="2 16" />
                            </svg>

                            <div class="benefit__card-wrapper" style={{paddingBottom : "20px" }}>

                                <article class="benefit__card">
                                    <div class="benefit__number-bubble">01</div>
                                    <div class="benefit__card-data">
                                        <p class="benefit__card-heading">Awareness of social and cultural Values</p>
                                        <p class="benefit__card-text">We infuse in our children the road to success
                                            passes
                                            through complying the basic principles of social and cultural values.</p>
                                    </div>
                                </article>

                                <article class="benefit__card">
                                    <div class="benefit__number-bubble">02</div>
                                    <div class="benefit__card-data">
                                        <p class="benefit__card-heading">Skilled based Education</p>
                                        <p class="benefit__card-text">What we teach our children today will become
                                            redundant for them in their practical life. We need to develop skills; they
                                            can apply and lead a successful life.</p>
                                    </div>
                                </article>

                                <article class="benefit__card">
                                    <div class="benefit__number-bubble">03</div>
                                    <div class="benefit__card-data">
                                        <p class="benefit__card-heading">Physical and psychological growth</p>
                                        <p class="benefit__card-text">Good health always infuses positive energy in the
                                            mind. People with good health always enjoys healthy lifestyle.</p>
                                    </div>
                                </article>

                                <article class="benefit__card">
                                    <div class="benefit__number-bubble">04</div>
                                    <div class="benefit__card-data">
                                        <p class="benefit__card-heading">Social and Emotional development</p>
                                        <p class="benefit__card-text">Children learn best when they are socially,
                                            emotionally and physically liberated. When children start knowing about
                                            themselves, their feelings, and expectations while interacting with others.
                                        </p>
                                    </div>
                                </article>

                            </div>
                        </div>

                    </div>

                </div>
          
            </div>
        </section>
    </>
  )
}

export default WhyChooseUs