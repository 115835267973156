import Header from "../components/Header/header";
import Footer from "../components/footer/footer";
import { Outlet } from "react-router-dom";

function Layout() {
    return (
<>
    <Header />
    <main className="main">
        <Outlet />
      </main>
    <Footer />

</>
    )
}

export default Layout;