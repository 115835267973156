import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './how_support_institutions.css'; // Import your component-specific CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';


function How_Support_Instituions() {
  return (
    <section className="content-section">
      <Container>
        {/* <h2 className="section-title">Our Support Services</h2> */}
        <Row>
          <Col md={6}>
            <ul className="support-list">
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                How well are the learners attaining and progressing in core subjects?
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                How good are learners in their personal, social and emotional developments?
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                How effective are teaching, learning, and methods of assessing learners' progress?
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                How well does the school's curriculum meet learners' needs?
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                How well does the school provide support services to the learners and promote their interests?
              </li>
            </ul>
          </Col>
          <Col md={6}>
            <ul className="support-list">
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                How effective are the leadership, management, and governance of the school?
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                Reviewing all systems of the institution and submitting a report against international or local standards.
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                Providing a professional development plan for teachers and leaders.
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                Building a strategic, action, and post-review action plan along with impact statements.
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                Ongoing review and support services to bring the school in line with international practices.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default How_Support_Instituions;
