import React from "react";
import { HashLink } from 'react-router-hash-link';
import "../../assets/css/main.css";
import "./testimonial.css";


function Testimonial() {
  return (
    <>
      <div class="testimonial__section">
        <section class="testimonial__wrapper">
          <p class="section__title">Testimonials</p>
          <p class="section__text">Our Clients Saying</p>

          <div class="container-fluid testimonial__slider-wrapper">
            <div class="row">
              <div
                class="col-xl-3 col-lg-3 col-0 testimonial__right-wrapper"
                style={{ height: "50vh" }}
              >
                <img
                  src={require("../../assets/images/person-1.png")}
                  alt=""
                  class="testimonial__person person__1"
                />
                <img
                  src={require("../../assets/images/person-2.png")}
                  alt=""
                  class="testimonial__person person__2"
                />
                <img
                  src={require("../../assets/images/person-3.png")}
                  alt=""
                  class="testimonial__person person__3"
                />
              </div>

              <div class="col-xl-6 col-lg-6 col-12 slider__wrapper">
                <div
                  id="carouselExampleIndicators"
                  class="carousel  carousel-fade"
                >
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      {/* <p class="testimonial__slider-heading">Dr. Majeed Sanad</p> */}
                      <p class="testimonial__slider-text">
                      "I've had the pleasure of knowing Mr. Khan for the past 10 years, and I can confidently say that he is one of the best quality assurance consultants and teacher trainers I've ever had the pleasure of working with." <HashLink class="brd" to ="/about-us#test">Read more</HashLink>
                      </p>
                      <img
                        src={require("../../assets/images/slider-person.png")}
                        class="testimonial__slider-img"
                        alt=""
                      />
                      <img
                        src={require("../../assets/images/quote.png")}
                        alt=""
                        class="slider-quote"
                      />
                    </div>
                    <div class="carousel-item">
                      <p class="testimonial__slider-heading">
                        Robort Fox
                      </p>
                      <p class="testimonial__slider-text">
                      "The croissants here are simply perfection! Golden and
                        flaky on the outside, light and buttery on the inside. I
                        can't get enough of them."
                        
</p> 


                      <img
                        src={require("../../assets/images/slider-person.png")}
                        class="testimonial__slider-img"
                        alt=""
                      />
                      <img
                        src={require("../../assets/images/quote.png")}
                        alt=""
                        class="slider-quote"
                      />
                    </div>
                    <div class="carousel-item">
                      <p class="testimonial__slider-heading">alex Hales</p>
                      <p class="testimonial__slider-text">
                        "The croissants here are simply perfection! Golden and
                        flaky on the outside, light and buttery on the inside. I
                        can't get enough of them."
                      </p>
                      <img
                        src={require("../../assets/images/slider-person.png")}
                        class="testimonial__slider-img"
                        alt=""
                      />
                      <img
                        src={require("../../assets/images/quote.png")}
                        alt=""
                        class="slider-quote"
                      />
                    </div>

                    <div class="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="0"
                        class="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-3 col-lg-3 col-0 testimonial__right-wrapper">
                <img
                  src={require("../../assets/images/person-4.png")}
                  alt=""
                  class="testimonial__person person__4"
                />
                <img
                  src={require("../../assets/images/person-5.png")}
                  alt=""
                  class="testimonial__person person__5"
                />
                <img
                  src={require("../../assets/images/person-6.png")}
                  alt=""
                  class="testimonial__person person__6"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Testimonial;
