import React from 'react';
import "./Faq.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";

const FaqItem = ({ question, answer }) => {
  return (


    <MDBContainer className="mt-5" style={{maxWidth: '1000px'}}>
    <MDBAccordion alwaysOpen initialActive={1}>
      <MDBAccordionItem collapseId={1} headerTitle={question}>
        {/* <strong>{question}</strong> */}
         {answer}
      </MDBAccordionItem>
    </MDBAccordion>
  </MDBContainer>
    // <div className="faq-item">
    //   <h2 className="faq-question">{question}</h2>
    //   <p className="faq-answer">{answer}</p>
    // </div>
  );
};

export default FaqItem;



