import React from 'react';
import './cookie_policy.css'

const CookiesPolicyPage = () => {
  return (
    <div className="cookies-policy">
      <h1 className="page-title">Cookies Policy</h1>

      <div className="content">
        <p>
          This website uses cookies to improve the user experience. By using our website, you consent to all cookies in
          accordance with our Cookie Policy.
        </p>

        <h2>1. What Are Cookies?</h2>
        <p>
          Cookies are small text files that are placed on your computer by websites that you visit. They are widely used
          to make websites work more efficiently and provide useful information to website owners.
        </p>

        <h2>2. How We Use Cookies</h2>
        <p>
          We use cookies for various purposes, including but not limited to:
        </p>
        <ul>
          <li>Analyzing site usage and improving content and services.</li>
          <li>Remembering your preferences and login information.</li>
          <li>Tracking user interactions with our website.</li>
        </ul>

        <h2>3. Disabling Cookies</h2>
        <p>
          You can disable cookies in your web browser settings. However, please note that disabling cookies may affect the
          functionality and user experience of this website.
        </p>

        <h2>4. Changes to this Cookies Policy</h2>
        <p>
          We may update this Cookies Policy from time to time. We recommend reviewing this policy periodically to stay
          informed about how we use cookies.
        </p>
      </div>
    </div>
  );
};

export default CookiesPolicyPage;
