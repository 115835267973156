import React, { useState } from "react";
import { WP_BASE_API_URL } from "../../utils/constants";

// interface BlogsViewIProps {
//   blog: any;
//   type?: string;
//   px?: string;
// }

const BlogItem = ({ blog, type = "default", px ="" }) => {
  const [displayImg, setDisplayImg] = useState(true);
  const todayDate = new Date(blog["date"]);
  const day = todayDate.getDate();

  const year = todayDate.getFullYear();

  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ][todayDate.getMonth()];

  const postDate = day + " " + month + " " + year;

  const handleImageError = (currentTarget) => {
    currentTarget.onerror = null; // prevents looping
    setDisplayImg(false);
    currentTarget.remove();
  };

  return (
    <div
      className={` flex-col w-full md:flex-row  md:max-w-[400px] 2xl:max-w-[500px] flex justify-center ${
        type === "related" && " shadow-md"
      }`}
    >
      <a href={`/blogs/${blog["_embedded"]["wp:term"]?.[0]?.[0].slug}/${blog["id"]}`}>
        <div className="hover:cursor-pointer  flex flex-col items-center w-full">
          <div className="w-full flex flex-col transition ease-in-out delay-100 hover:scale-110 ">
            {displayImg && (
              <img
                src={
                  blog["_embedded"]["wp:featuredmedia"]
                    ? blog["_embedded"]["wp:featuredmedia"]?.[0]["source_url"]
                    : "/avatar.png"
                }
                className="object-cover object-center"
                onError={({ currentTarget }) => handleImageError(currentTarget)}
                width={500}
                height={280}
                alt={blog["title"]["rendered"]}
                style={{
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            )}
          </div>
          {!displayImg && (
            <div className="flex border-2 text-2xl font-semibold justify-center items-center w-full h-full bg-skin-primary-white">
              No Preview
            </div>
          )}

          <div className="h-full  min-h-[50px]  mt-[30px]">
            <p
              className={`$ text-lg line-clamp-3 tracking-tight   xl:text-[22px] text-left break-all font-medium text-skin-button-black   hover:underline ${px}`}
            >
              {blog["title"]["rendered"]}
            </p>
          </div>
          <div
            className={` ${px} py-4 w-full flex flex-col items-center gap-8 px-3`}
          >
            <div className="h-full min-h-[100px] ">
              <div
                className="text-ellipsis line-clamp-5  overflow-hidden text-left  text-[#6B6B6B] text-sm  sm:text-justify xl:text-left xl:text-[16px] xl:font-normal "
                dangerouslySetInnerHTML={{
                  __html: blog["excerpt"]["rendered"],
                }}
              ></div>
            </div>
            <div className="flex justify-between w-full h-full">
              <p className="text-sm text-[#6B6B6B] xl:text-[16px]">
                {postDate}
              </p>
              <a
                href={`/blogs/${blog["_embedded"]["wp:term"]?.[0]?.[0].slug}/${blog["id"]}`}
                className="text-sm text-skin-button-primary hover:underline xl:text-[16px]  flex flex-col items-end "
              >
                Read Full Article
              </a>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default BlogItem;
