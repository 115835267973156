import React from 'react';

import './privacy_policy.css'
import AccountDeletion from './AccountDeletion';
const PrivacyPolicyPage = () => {
  return (<>
    <div className="privacy-policy">
   <h1 style={{ textAlign: 'center', marginTop: 30 }}>Privacy Policy</h1>

      <h2>1. Introduction</h2>
      <p>
        Edu-Linx sets clear, measurable, and achievable learning outcomes and provides the necessary resources and support
        system to achieve those outcomes. The quality of practices is ensured by evidence-based teaching and learning
        strategies and ongoing professional development. Our operations and effective systems of internal quality assurance
        for continuous improvement aim to equip learners with the knowledge, skills, and personal attributes needed for
        success and positive contributions to society.
      </p>

      <h2>2. Data Collection and Usage</h2>
      <p>
        Edu-Linx may collect and use personal information for various purposes, including but not limited to the
        following:
      </p>
      <ul>
        <li>Facilitating user registration and authentication on our platform.</li>
        <li>Providing personalized learning experiences and recommendations.</li>
        <li>Communicating with users, including sending updates and educational content.</li>
        <li>Improving our services and conducting research.</li>
      </ul>
      <p>
        We are committed to protecting the privacy and security of your data. We do not share your personal information
        with third parties without your consent.
      </p>

      <h2>3. Website Usage</h2>
      <p>
        By using the Edu-Linx website, you agree to abide by the following terms and conditions:
      </p>
      <ul>
        <li>You agree to use the website and its content for educational and non-commercial purposes only.</li>
        <li>You are responsible for maintaining the confidentiality of your account and password.</li>
        <li>You may not use the website in any way that could harm, disable, overburden, or impair our services.</li>
        <li>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</li>
      </ul>

      <h2>4. Changes to this Privacy Policy</h2>
      <p>
        Edu-Linx reserves the right to update or modify this Privacy Policy at any time. Any changes will be posted on
        this page, and the date of the last update will be revised accordingly. We encourage you to review this Privacy
        Policy periodically to stay informed about how we are protecting your information.
      </p>

      <h2>5. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or your data, please contact us at
        info@edu-linx.com.
      </p>
    </div>
     <div className="privacy-policy">
     <h1 style={{ textAlign: 'center', paddingTop: 30 }}>Account & Data Deletion</h1>

        <p>
    
        Thank you for using Evaluate. We are committed to protecting your privacy and ensuring that your personal data is handled responsibly. This Account and Data Deletion Policy outlines the procedures for deleting your account and associated data from our platform.
<br></br>
<br></br><p>
We are sad to se you go. If you wish to delete your account from Evaluate, please follow these steps:</p>
<br></br>
1. Enter your Email, UserID and Passoword in the given fields below
<br></br>
2. Enter reasons on why you opted for account deletion and also give suggestions
<br></br>
3. Then After Checking Your Information Press The Delete Account Button
<br></br><br></br>

<h2 style={{paddingBottom: 20, paddingTop: 20}}>Retention Period</h2>
<p>We will retain your personal data only for as long as necessary to fulfill the purposes outlined in our Privacy Policy or as required by law. After the retention period expires, we will securely delete or anonymize your data in accordance with our data retention practices.</p>


<h2 style={{paddingBottom: 20, paddingTop: 20}}>Questions and Concerns</h2>
<p>If you have any questions or concerns about our Account and Data Deletion Policy, please contact us at Info@Edu-linx.com. We are committed to ensuring that your privacy rights are respected and will address any inquiries promptly.
</p>


<h2 style={{paddingBottom: 20, paddingTop: 20}}>Changes to this Policy</h2>
<p>We reserve the right to update or modify this Account and Data Deletion Policy at any time. We will notify you of any changes by posting the revised policy on our website or through other appropriate means. Your continued use of Evaluate after any such changes constitutes acceptance of the updated policy.</p>


<h2 style={{paddingBottom: 20, paddingTop: 20}}>Effective Date</h2>
<p>This Account and Data Deletion Policy is effective as of 03/12/2024 and supersedes any previous versions.</p>

        </p>
  
       
       <AccountDeletion/>
      </div>
      
      
</>
  );
};

export default PrivacyPolicyPage;